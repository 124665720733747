
@charset "utf-8";

@import "./partials/abstracts/variables";

.container-standard-page {

    a,
    a:active,
    a:link,
    a:visited {
        color: $green;
    }

    h1, h2, h3, h4, h5, h6 {
        color: $green;

        a {
            color: $dark-gray;
            text-decoration: underline;
        }
    }

    .page-title {
        color: $green;
        font-weight: bold;
        margin-top: 20px;
    }

    .anchorTagLinks {
        ul {
            list-style: none;

            li {
                padding: 1% 0%;
            }
        }
    }

    table {
        margin-bottom: 20px;
        max-width: 100%;
        width: 100%;
    }

    .panel {
        border: none;
        border-radius: 0;
        box-shadow: none;

        .panel-heading {
            border: none;
            color: $dark-gray;
            font-size: 16px;
            padding: 0;

            a {
                background-color: $green;
                color: white;
                display: inline-block;
                font-weight: bold;
                padding: 5px 10px;
                position: relative;
                text-decoration: none;
                width: 100%;
            }

            a:after {
                content: "\f0d7";
                font-family: "Font Awesome 5 Free";
                position: absolute;
                line-height: 1;
                margin-top: -10px;
                right: 20px;
                top: 50%;
            }

            a.collapsed {
                background-color: $lightest-gray;
                color: $dark-gray;
            }

            a.collapsed:after {
                content: "\f0da";
            }
        }

        .panel-body {
            border: none;
        }
    }
}
