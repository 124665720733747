.container-standard-page a,
.container-standard-page a:active,
.container-standard-page a:link,
.container-standard-page a:visited {
  color: #4d7c6e; }

.container-standard-page h1, .container-standard-page h2, .container-standard-page h3, .container-standard-page h4, .container-standard-page h5, .container-standard-page h6 {
  color: #4d7c6e; }
  .container-standard-page h1 a, .container-standard-page h2 a, .container-standard-page h3 a, .container-standard-page h4 a, .container-standard-page h5 a, .container-standard-page h6 a {
    color: #606060;
    text-decoration: underline; }

.container-standard-page .page-title {
  color: #4d7c6e;
  font-weight: bold;
  margin-top: 20px; }

.container-standard-page .anchorTagLinks ul {
  list-style: none; }
  .container-standard-page .anchorTagLinks ul li {
    padding: 1% 0%; }

.container-standard-page table {
  margin-bottom: 20px;
  max-width: 100%;
  width: 100%; }

.container-standard-page .panel {
  border: none;
  border-radius: 0;
  box-shadow: none; }
  .container-standard-page .panel .panel-heading {
    border: none;
    color: #606060;
    font-size: 16px;
    padding: 0; }
    .container-standard-page .panel .panel-heading a {
      background-color: #4d7c6e;
      color: white;
      display: inline-block;
      font-weight: bold;
      padding: 5px 10px;
      position: relative;
      text-decoration: none;
      width: 100%; }
    .container-standard-page .panel .panel-heading a:after {
      content: "\f0d7";
      font-family: "Font Awesome 5 Free";
      position: absolute;
      line-height: 1;
      margin-top: -10px;
      right: 20px;
      top: 50%; }
    .container-standard-page .panel .panel-heading a.collapsed {
      background-color: #F5F5F5;
      color: #606060; }
    .container-standard-page .panel .panel-heading a.collapsed:after {
      content: "\f0da"; }
  .container-standard-page .panel .panel-body {
    border: none; }
